import React from 'react';

const GridItem = ({ image, text, link }) => {
  // If a link is provided, split the text into the part before the link and the rest
  const textParts = link ? text.split(' ') : [text];

  return (
    <div style={gridItem}>
      <img src={image} alt={text} style={imageStyle} />
      <p>
        {link ? (
          <>
            <a href={link} target="_blank" rel="noopener noreferrer" style={linkStyle}>
              {textParts[0]} {/* The linked word */}
            </a>
            {' '}{textParts.slice(1).join(' ')} {/* The rest of the text */}
          </>
        ) : (
          text
        )}
      </p>
    </div>
  );
};
// Styles (same as before)

const gridItem = {
    textAlign: 'center',
    justifyContent: 'space-between',
  };
  
  const imageStyle = {
    width: '400px',
    height: '200px',
    objectFit: 'cover',
    marginBottom: '5px',
  };

  const linkStyle = {
    color: '#3498db',
    textDecoration: 'none',
  };

export default GridItem;