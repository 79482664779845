import React from 'react';
import GridItem from '../GridItem/GridItem';

const Grid = ({ items }) => {
  // Split items into groups of 3 for each row
  const rows = [];
  for (let i = 0; i < items.length; i += 3) {
    rows.push(items.slice(i, i + 3));
  }

  return (
    <div style={gridContainer}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} style={rowStyle}>
          {row.map((item) => (
            <GridItem 
              key={item.id} 
              image={item.image} 
              text={item.text} 
              link={item.link} />
          ))}
        </div>
      ))}
    </div>
  );
};

const gridContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  marginBottom: '20px',
};

export default Grid;