import React from 'react';
import './css/Portfolio.css'
import ymFeatureGraphic from '../img/yogamatt/feature/ym-feature-graphic.png'
import catAppFeatureGraphic from '../img/cat-app/Home Page 01.png'
import Grid from '../components/Grid/Grid';

const Portfolio = () => {


    const items = [
      { 
        id: 1, 
        image: ymFeatureGraphic, 
        text: 'Yogamatt is a location-based booking service helping to add bookings to your classes, and inspiring new classes in new places.',
        link: 'https://yogamatt.info'
      },
      { 
        id: 2, 
        image: catAppFeatureGraphic, 
        text: 'Cat App is a an app that finds and locates cats in your area.',
        link: null
      },
      { 
        id: 3, 
        image: 'https://via.placeholder.com/150', 
        text: 'Item 3', 
        link: null 
      },
      { 
        id: 4, 
        image: 'https://via.placeholder.com/150', 
        text: 'Item 4', 
        link: null 
      },
      { 
        id: 5, 
        image: 'https://via.placeholder.com/150', 
        text: 'Item 5', 
        link: null 
      },
      { 
        id: 6, 
        image: 'https://via.placeholder.com/150', 
        text: 'Item 6', 
        link: null 
      },
      // Add more items as needed
    ];

  return (
    <section style={portfolioStyle}>
      <h2>Portfolio</h2>
      <Grid items={items} />
    </section>
  );
};

const portfolioStyle = {
  background: '#f9f9f9',
  padding: '50px 20px',
  textAlign: 'center',
};

export default Portfolio;